const tabPanelSwitchEvent = new Event('tabPanelSwitch'); // only needed for testing purposes
export default class Tabs {
	options = {
		id: null,
		navSelector: '[js-element~="tabList"]',
		tabsSelector: '[js-element~="tab"]',
		panelsSelector: '[js-element~="tabPanel"]',
	};

	constructor(element, config, onChange) {
		if (!element) throw Error('No Tabs element provided');

		this.element = element;
		this.options = { ...this.options, ...config };
		this.panels = this.element.querySelectorAll(this.options.panelsSelector);
		this.tabs = this.element.querySelectorAll(this.options.tabsSelector);
		this.tablist = this.element.querySelector(this.options.navSelector);
		this.clickHandler = this.tabsClickHandler.bind(this);
		this.id = this.setId();
		this.activeTabIndex = 0;
		this.onChange = onChange;

		this.process();
	}

	process() {
		this.tablist.setAttribute('aria-multiselectable', 'false');
		this.tablist.setAttribute('role', 'tablist');
		this.element.setAttribute('id', this.id);

		this.createTabs();
		this.createPanels();

		this.getActiveTab();

		this.setActivePanel(
			document.getElementById(
				this.element
					.querySelector(this.options.tabsSelector + '[aria-selected="true"]')
					.getAttribute('aria-controls')
			)
		);

		this.addTabsEventListeners();
	}

	createTabs() {
		this.tabs.forEach((tab, index) => {
			tab.setAttribute('role', 'tab');
			tab.setAttribute('aria-controls', this.id + '-tab-panel-' + (index + 1));
			tab.setAttribute('aria-setsize', this.tabs.length);
			tab.setAttribute('aria-posinset', index + 1);
			tab.id = this.id + '-tab-label-' + (index + 1);
			this.setDynamicTabAttributes(tab);
		});
	}

	setDynamicTabAttributes(tab, force = false) {
		if (!force) {
			if (tab.ariaSelected === 'true') return;
		}

		tab.setAttribute('aria-selected', 'false');
		tab.setAttribute('tabindex', '-1');
	}

	setActiveTab(el) {
		if (!el) return;

		el.setAttribute('aria-selected', 'true');
		el.setAttribute('tabindex', '0');
		this.activeTabIndex = new Number(el.getAttribute('aria-posinset')) - 1;
	}

	createPanels() {
		for (let i = 0; i < this.panels.length; i++) {
			const panel = this.panels[i];

			panel.setAttribute('role', 'tabpanel');
			panel.setAttribute('aria-labelledby', this.id + '-tab-label-' + (i + 1));
			panel.id = this.id + '-tab-panel-' + (i + 1);

			this.setDynamicPanelAttributes(panel);
		}
	}

	setDynamicPanelAttributes(el) {
		el.setAttribute('aria-hidden', 'true');
	}

	setActivePanel(el) {
		if (!el) return;

		el.removeAttribute('aria-hidden');

		el.dispatchEvent(tabPanelSwitchEvent);
	}

	addTabsEventListeners() {
		this.tabs.forEach((tab) => {
			tab.addEventListener('click', this.clickHandler);
		});

		this.tablist.addEventListener('keydown', (e) => {
			// Move right
			if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
				this.tabs.forEach((tab) => {
					this.setDynamicTabAttributes(tab, true);
				});

				let index = 0;
				let destinationIndex = 0;
				// move right
				if (e.key === 'ArrowRight') {
					index = this.activeTabIndex + 1;
					if (index >= this.tabs.length) {
						destinationIndex = 0;
					} else {
						destinationIndex = index;
					}
					// move left
				} else {
					index = this.activeTabIndex - 1;
					if (index < 0) {
						destinationIndex = this.tabs.length - 1;
					} else {
						destinationIndex = index;
					}
				}
				this.setActiveTab(this.tabs[destinationIndex]);
				this.tabs[destinationIndex].focus();
			}
		});
	}

	removeTabsEventListeners() {
		this.tabs.forEach((tab) => {
			tab.removeEventListener('click', this.clickHandler);
		});
	}

	tabsClickHandler(event) {
		this.tabChange(event.currentTarget);
		if (event.currentTarget.tagName === 'A') {
			event.preventDefault();
		}
	}

	tabChange(el, onChange = this.onChange) {
		this.tabs.forEach((tab) => {
			this.setDynamicTabAttributes(tab, true);
		});

		this.panels.forEach((panel) => {
			panel.setAttribute('aria-hidden', 'true');
		});

		this.setActiveTab(el);

		// in the tvguide, there's only 1 panel that gets replaced dynamically
		if (this.panels.length === 1) {
			this.setActivePanel(this.panels[0]);
		} else {
			this.setActivePanel(
				document.getElementById(el.getAttribute('aria-controls'))
			);
		}

		if (typeof onChange === 'function') {
			onChange(el);
		}
	}

	getActiveTab() {
		if (window.location.hash) {
			if (this.checkHash(window.location.hash)) {
				const hash = this.element.querySelector(
					'a[href="' + window.location.hash + '"]'
				);
				this.setActiveTab(hash);
				this.setActivePanel(this.panels[this.activeTabIndex]);
				return;
			}
		}

		this.setActiveTab(this.getActiveTabElement());
	}

	getActiveTabElement() {
		const els = Array.from(this.tabs);
		const activeTabElement =
			els.find((el) => el.ariaSelected === 'true') || els[0];

		return activeTabElement;
	}

	checkHash(hash) {
		return Array.from(this.tabs).find(
			(tab) => tab.getAttribute('href') === hash
		);
	}

	setId() {
		if (this.options.id) {
			return this.options.id;
		} else if (this.element.id) {
			return this.element.id;
		} else {
			return 'tabs-' + this.getRandomInt(1000);
		}
	}

	getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	destroy() {
		this.removeTabsEventListeners();

		this.tablist.removeAttribute('aria-multiselectable');
		this.tablist.setAttribute('role', 'group');

		this.tabs.forEach((tab) => {
			tab.removeAttribute('role');
			tab.removeAttribute('aria-controls');
			tab.removeAttribute('aria-setsize');
			tab.removeAttribute('aria-posinset');
			tab.removeAttribute('aria-selected');
			tab.removeAttribute('tabindex');
			tab.blur();
		});

		this.panels.forEach((panel) => {
			panel.removeAttribute('role');
			panel.removeAttribute('aria-labelledby');
			panel.removeAttribute('aria-hidden');
		});
	}
}
