import Alert from '../alert/alert';

export default class EditableTeaserGroup {
	constructor(element, { onDelete } = {}) {
		this.element = element;
		this.forms = this.element.querySelectorAll(
			'[js-element~="teaserEditForm"]'
		);
		this.checkbox = this.element.querySelector(
			'[js-element~="teaserEditCheckbox"]'
		);
		this.body = document.body;
		this.EDITING_CLASS = 'is-editing-teasers';
		this.teasers = this.element.querySelectorAll('[js-module~="teaser"]');
		this.container = null;
		this.totalItems = null;
		this.onDelete = onDelete;
		this.isEmpty = false;
		this.removedTeaserId = null;
		this.removedTeaserForm = null;

		this.createEditableTeaserGroup();
	}

	createEditableTeaserGroup() {
		this.forms.forEach((form) => {
			form.addEventListener('submit', (e) => {
				this.onClick(e);
			});
		});

		this.checkbox.addEventListener('change', () => this.toggleHighlight(), {
			passive: true,
		});
	}

	onClick(event) {
		this.sendAjaxRequestToMyList(event.currentTarget);
		event.preventDefault();
	}

	removeTeaserFromList(teaser) {
		this.container = teaser.closest('[js-element~="teaserEditContainer"]');
		this.totalItems = this.container.children.length;

		teaser.addEventListener(
			'transitionend',
			(e) => {
				this.deleteTeaser(e);
			},
			{
				passive: true,
				once: true,
			}
		);
		teaser.classList.add('is-fading');
	}

	deleteTeaser(e) {
		e.currentTarget.parentElement.remove(); // delete the item
		this.totalItems--;

		if (this.totalItems === 0) {
			this.body.classList.remove(this.EDITING_CLASS);
			this.emptyState();
		}

		if (typeof this.onDelete === 'function') {
			this.onDelete(this);
		}
	}

	emptyState() {
		this.isEmpty = true;
	}

	showError(el) {
		// remove the spinner
		el.classList.remove('is-in-progress');
		new Alert('warning', window.i18n.alertMyListError);
	}

	toggleHighlight() {
		if (this.body.classList.contains(this.EDITING_CLASS)) {
			this.removeActiveState();
		} else {
			this.setActiveState();
		}
	}

	removeActiveState() {
		this.element.classList.remove('is-editing');
		this.body.classList.remove(this.EDITING_CLASS);
		this.teasers.forEach((tsr) => {
			tsr.removeAttribute('aria-hidden');
			tsr.removeAttribute('tabindex');
		});
		window.removeEventListener(
			'click',
			(e) => {
				this.globalClose(e);
			},
			{ passive: true, once: true }
		);
	}

	setActiveState() {
		this.element.classList.add('is-editing');
		this.body.classList.add(this.EDITING_CLASS);
		this.teasers.forEach((tsr) => {
			tsr.setAttribute('aria-hidden', 'true');
			tsr.setAttribute('tabindex', '-1');
		});
		window.addEventListener(
			'click',
			(e) => {
				this.globalClose(e);
			},
			{ passive: true, once: true }
		);
	}

	reset() {
		this.removeActiveState();
		this.checkbox.checked = false;
	}

	globalClose(e) {
		if (e.target instanceof Element) {
			if (
				e.target.classList.contains('teaser__edit-toggle') ||
				(e.target.hasAttribute('js-element') &&
					e.target.getAttribute('js-element').includes('teaserEditCheckbox')) ||
				e.target.closest('.swimlane__control') !== null
			) {
				return;
			}
		}

		const buttons = Array.from(
			document.querySelectorAll(
				'[js-element~="teaserEditButton"], [js-element~="teaserEditButton"] *'
			)
		);

		if (buttons.indexOf(e.target) < 0) {
			this.reset();
		}
	}

	async sendAjaxRequestToMyList(frm) {
		// we show a spinner
		frm.classList.add('is-in-progress');

		const response = await fetch(frm.action, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				'x-csrf-token': window.App?.csrfToken,
			},
		});

		if (!response.ok && response.status !== 404) {
			this.showError(frm);
			return;
		}
		this.removedTeaserForm = frm;
		this.removedTeaserId = frm.dataset.id;
		this.removeTeaserFromList(frm.closest('x-teaser'));
	}
}
