import Modal from '../../classes/modal/modal';
import browsersniffing from '../../utils/browsersniffing/browsersniffing';

function init() {
	const mobileOs = browsersniffing.detectMobileOS();

	if (mobileOs !== false) {
		if (
			document.body.getAttribute('js-module') &&
			document.body.getAttribute('js-module').includes('playerMobileDetect')
		) {
			showMobileModal(mobileOs);
		}

		document.querySelectorAll('[js-module~="mobileDetect"]').forEach((link) => {
			link.addEventListener('click', function (e) {
				if (!document.body.classList.contains('is-editing-teasers')) {
					showMobileModal(mobileOs);
					e.preventDefault();
				}
			});
		});
	} else {
		if (!browsersniffing.detectSupportedBrowser()) {
			const html = document
				.querySelector('#unsupportedBrowsersModalTemplate')
				?.content.cloneNode(true);

			if (!html) return;

			new Modal('Unsupported', {
				message: html,
			});
		}
	}
}

function showMobileModal(os) {
	if (os === 'iOS' || os === 'AndroidOS') {
		const html = document
			.querySelector('#mobileAppModalTemplate')
			?.content.cloneNode(true);

		if (!html) return;

		let lfvpDeepLinkScheme = `${window.App.scheme}:`;
		// remove protocol (https) from url and keep everything else. E.g. `vtmgo.be/vtmgo/trending?blah=meh`
		let lfvpDeepLink = `${lfvpDeepLinkScheme}${
			window.location.href.match(/\/\/[^/]+\/?([^:]+)/)[0]
		}`;

		// add custom utm params (referrer) if there are no utm params already in the url
		if (
			!window.location.search &&
			document.referrer !== '' &&
			document.referrer.indexOf(location.protocol + '//' + location.host) !== 0 // skip own domain
		) {
			const referrer = new URL(document.referrer);
			lfvpDeepLink += `?utm_source=${referrer.hostname.replace(
				/w+.?/i,
				''
			)}&utm_medium=referral`;
		}

		html.querySelector('[js-element~="deeplink"]').href = lfvpDeepLink;
		if (os === 'AndroidOS') {
			html
				.querySelector('[js-element~="appstore-ios"]')
				.classList.add('is-hidden');
		} else {
			html
				.querySelector('[js-element~="appstore-android"]')
				.classList.add('is-hidden');
		}
		new Modal('Mobile', {
			message: html,
		});
	} else {
		// it's not ios or android: show alternative modal
		const html = document
			.querySelector('#mobileAppAlternativeModalTemplate')
			?.content.cloneNode(true);

		if (!html) return;

		new Modal('MobileAlt', {
			message: html,
		});
	}
}

export default {
	init,
};
