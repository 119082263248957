import { debounce } from '../../utils/throttle/debounce';

import tracking from '../tracking/tracking.logic';

let search,
	searchModule,
	input,
	clear,
	resultsWrapper,
	observer,
	defaults = {},
	errorMsg,
	searchEndpoint,
	searchQuery;

window.dataLayer = window.dataLayer || [];
window.App = window.App || {};

function init() {
	searchModule = document.querySelector('[js-module~="search"]');
	if (!searchModule) return;

	searchQuery = undefined;
	search = searchModule.closest('search');
	defaults = defaults || {};
	defaults.debounce = 500;
	defaults.minLength = 1;
	defaults.onFetch = showSpinner;

	if (window.location.search) {
		searchQuery = getSearchQueryValue(window.location.search);
	}

	_bootstrap();
	addResultsEventListeners();
}

function _bootstrap() {
	clear = searchModule.querySelector('.search__clear');
	input = searchModule.querySelector('.search__field[name="query"]');
	searchEndpoint = searchModule.dataset.searchEndpoint;
	resultsWrapper = search.querySelector('.search__results-wrapper');

	errorMsg = window.i18n.search.error;

	input.setAttribute('autocomplete', 'off');
	if (searchQuery !== undefined) {
		input.value = searchQuery;
	}

	focusInput();
	updateReset();

	input.addEventListener('keydown', debounce(keyDown, defaults.debounce));
	input.addEventListener('keydown', () => updateReset());

	// clicking on the clear button takes you back to the search `front door`
	clear.addEventListener('click', () => {
		window.location = window.location.pathname;
	});
	searchModule.addEventListener('submit', (e) => {
		e.preventDefault();
	});

	// if we click the back button (coming from ?query=something) and want to end
	// up at the front door (/zoeken)
	window.addEventListener(
		'popstate',
		() => {
			window.location.reload();
		},
		{ passive: true }
	);

	// a block of results is considered `viewed` when the first item of a block
	// is fully visible
	const resultBlocks = document.querySelectorAll(
		'.search__results .search__item:first-child a'
	);
	createTrackingObserver(resultBlocks);
}

function keyDown(e) {
	// e.location === 0 -> if it's a regular character (a-z 0 - 9),
	// not a meta key (shift, alt, ...) or anything else.
	// e.location === 3 -> numpad

	if (
		(e.location === 0 || e.location === 3) &&
		!e.key.includes('Arrow') &&
		!e.key.includes('Esc') &&
		!e.key.includes('Page') &&
		!e.key.includes('Tab') &&
		e.key !== 'Home' &&
		e.key !== 'End'
	) {
		if (input.value.length >= defaults.minLength) {
			searchQuery = encodeURIComponent(input.value);
			sendSearchQuery(searchQuery);
		} else {
			// deleting the value in the input field will drop you off
			// at the front door
			window.location = window.location.pathname;
		}
	}
}

function updateReset() {
	if (input.value.length <= 0) {
		clear.classList.add('is-hidden');
	} else {
		clear.classList.remove('is-hidden');
	}
}

async function sendSearchQuery(query) {
	if (typeof defaults.onFetch === 'function') {
		defaults.onFetch();
	}

	const searchQueryUrl = searchEndpoint + '?query=' + query;

	const response = await fetch(searchQueryUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'text/xml',
			'X-Requested-With': 'XMLHttpRequest',
		},
	});

	if (!response.ok) {
		onRequestFail();
		return;
	}

	const content = await response.text();
	// wasFetchRequest = true;
	onRequestSuccess(content);
	onRequestComplete();
}

function onRequestSuccess(response) {
	let responseContainer,
		tempResponseContainer = document.createElement('div');

	// remove all previous results
	resultsWrapper.innerHTML = '';
	// remove the previous intersectionObserver
	observer.disconnect();

	// print out the new
	tempResponseContainer.innerHTML = response;
	responseContainer = tempResponseContainer.querySelector(
		'[js-element~="searchOutput"]'
	);
	resultsWrapper.insertAdjacentHTML('beforeend', responseContainer.innerHTML);

	const resultBlocks = document.querySelectorAll(
		'.search__results .search__item:first-child a'
	);

	createTrackingObserver(resultBlocks);
	addResultsEventListeners();
}

function onRequestFail() {
	const msgContainer = document.createElement('div');
	const msgNode = document.createElement('p');

	// create some html
	msgContainer.classList.add('search__results-block');
	msgNode.classList.add('search__results-msg');
	msgNode.textContent = errorMsg;

	msgContainer.appendChild(msgNode);

	// remove content
	resultsWrapper.innerHTML = '';

	// replace it with our newly created html
	resultsWrapper.appendChild(msgContainer);
}

function onRequestComplete() {
	search.classList.remove('is-loading');
}

function showSpinner() {
	search.classList.add('is-loading');
}

function focusInput() {
	if (input === undefined) {
		input = document.querySelector('.search__field');
	}

	input.focus();
}

function getSearchQueryValue(url) {
	const params = new URLSearchParams(url);
	return decodeURIComponent(params.get('query'));
}

function addResultsEventListeners() {
	// send click events on all results to GTM
	const results = search.querySelectorAll('[js-module~="teaser"]');

	results.forEach(function (result) {
		const recentSearchActionUrl = result.getAttribute(
			'data-recent-search-action'
		);

		result.addEventListener('click', () => {
			window.history.pushState(
				{},
				null,
				searchQuery?.length
					? window.App.searchUrl + '?query=' + searchQuery
					: window.App.searchUrl
			);
			addToRecentSearchResults(recentSearchActionUrl);
		});
	});
}

async function addToRecentSearchResults(url) {
	await fetch(url, {
		method: 'PUT',
		headers: {
			'x-csrf-token': window.App.csrfToken,
			'X-Requested-With': 'XMLHttpRequest',
		},
	});
}

/* TRACKING */
function createTrackingObserver(elements) {
	if ('IntersectionObserver' in window) {
		observer = new IntersectionObserver(onIntersection, {
			threshold: 1,
		});

		elements.forEach((el) => {
			observer.observe(el);
		});
	}
}

function onIntersection(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			const results = entry.target.closest('.search__results');
			tracking.init(results);
			// once observed and tracked, now stop observing that entry
			observer.unobserve(entry.target);
		}
	});
}

export default {
	init,
};
