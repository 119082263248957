import EditableTeaserGroup from '../../classes/editableTeaserGroups/editableTeaserGroups';
import swimlane from '../swimlane/swimlane';
import myList from '../my-list/my-list';

window.dataLayer = window.dataLayer || [];
let editableTeaserGroupInstances = null;

function init() {
	const editableTeaserGroups = document.querySelectorAll(
		'[js-module~="teaserEdit"]'
	);
	editableTeaserGroupInstances = [];

	// If you go into edit mode and leave the page without exiting the edit mode,
	// and if you used the back button to come back to this page, we show the page
	// from cache and thus the checkbox will be checked and show the edit mode
	// The `pageshow` event gets triggered on fresh & cached pages and we use that
	// to reset everything
	window.addEventListener('pageshow', resetAll, { passive: true, once: true });

	editableTeaserGroups.forEach((editableTeaserGroup) => {
		editableTeaserGroupInstances.push(
			createEditableTeaserGroup(editableTeaserGroup)
		);
	});
}

function createEditableTeaserGroup(element) {
	new EditableTeaserGroup(element, { onDelete });
}

function resetAll() {
	editableTeaserGroupInstances.forEach((instance) => {
		instance?.reset();
	});
}

function destroy(instance) {
	const index = editableTeaserGroupInstances.indexOf(instance);
	if (index >= 0) {
		editableTeaserGroupInstances.splice(index, 1);
	}
}

function onDelete(instance) {
	// update the My List button in the (top) banner (if any)
	// and only update it if the event came from a My List swimlane
	if (
		(document.querySelectorAll('x-banner').length ||
			document.querySelectorAll('top-banner').length) &&
		instance.removedTeaserForm.action.includes('/userData/myList')
	) {
		myList.toggleButtonState(instance.removedTeaserId, true);
	}

	// tracking
	myList.trackMyListInteraction(instance.removedTeaserForm);

	// if the AJAX request is successful after deleting the last item, we
	// redirect to the storefront where this swimlane was situated
	// => this is only for `Kijk Verder`
	// On the Mijn Lijst swimlane detail and standalone page, we show a `clean slate`
	// on a storefront
	if (document.querySelector('[js-module~="mainPage"]') !== null) {
		if (instance.isEmpty) {
			let editRow = instance.container?.closest('[js-element~="rowEdit"]');
			if (editRow) {
				editRow.remove();
				editRow = null; // garbage collection
			}
		} else {
			// update the swimlane/carousel so it recalculates its width and slide groups
			const index = Array.prototype.indexOf.call(
				document.querySelectorAll('x-swimlane'),
				instance.container.closest('[js-module~="teaserEdit"]')
			);
			swimlane.update(index);
		}
	} else {
		// on a swimlane detail page
		if (instance.isEmpty) {
			if (window.App.redirectUrl) {
				window.location = window.App.redirectUrl;
			} else {
				const cleanslate = document.querySelector(
					'[js-module~="taxonomyCleanslate"]'
				);
				let button = document.querySelector('[js-module~="taxonomyActions"]');

				if (cleanslate) {
					cleanslate.removeAttribute('aria-hidden');
				}

				if (button) {
					button.remove();
					button = null; // garbage collection
				}
			}
		}
	}

	if (instance.isEmpty) {
		destroy(instance);
	}
}

export default {
	init,
	createEditableTeaserGroup,
	onDelete,
};
